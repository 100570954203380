import type {
  Article,
  MagazineArticle,
  Mention,
  MentionOrCustomerArticle,
  PaperArticle,
  PodcastEpisode,
  ProgramAiring,
  RadioClip,
  TvCaption,
  TvLogoAppearance,
  TvSuper,
} from "shared/types/mentions";
import {
  BroadcastMentionType,
  MentionType,
  SocialMentionType,
} from "shared/types/mentions";

export function isMention(
  mention: MentionOrCustomerArticle
): mention is Mention {
  return "timestamp" in mention;
}

export function isTvCaption(mention: Mention): mention is TvCaption {
  return mention.type === MentionType.tv_caption;
}

export function isPaperArticle(mention: Mention): mention is PaperArticle {
  return mention.type === MentionType.paper_article;
}

export function isOnlineArticle(mention: Mention): mention is MagazineArticle {
  return mention.type === MentionType.article;
}

export function isMagazineArticle(
  mention: Mention
): mention is MagazineArticle {
  return mention.type === MentionType.magazine_article;
}

export function isMentionWithTvEyes(
  mention: Mention
): mention is TvCaption | PodcastEpisode {
  return "tveyes_player_url" in mention;
}

export function isMentionWithAppRedirect(
  mention: Mention
): mention is Article | PaperArticle | MagazineArticle {
  return "in_app_redirect" in mention;
}

export function isMentionWithBody(
  mention: Mention
): mention is Article | PaperArticle {
  return "body" in mention || "translated_body" in mention;
}

export function isMentionWithDatahub(
  mention: Mention
): mention is PaperArticle | RadioClip | TvCaption {
  return "datahub" in mention;
}

export function isMentionWithMediatrack(
  mention: Mention
): mention is PaperArticle | MagazineArticle {
  return "mediatrack_international_print" in mention;
}

export function isMentionRequiresAllowanceCheck(
  mention: Mention
): mention is PaperArticle | MagazineArticle {
  return "requires_allowance_check" in mention;
}

export function isMentionWithSyndications(
  mention: Mention
): mention is Extract<Mention, Article | PaperArticle | RadioClip | TvCaption> {
  return "syndications" in mention;
}

export function isMentionWithRelatedArticle(
  mention: Mention
): mention is Mention & {
  related_article: Article | PaperArticle;
} {
  return "related_article" in mention && Boolean(mention.related_article);
}

export function isMentionFinancialTimes(mention: Mention) {
  return (
    ("license" in mention && mention.license === "financial-times") ||
    mention.source?.name === "Financial Times"
  );
}

export function isMentionOutsideSourceRetentionPeriod(
  mention: Mention
): mention is Article | PaperArticle | MagazineArticle {
  return "outside_source_retention_period" in mention;
}

export function isTvMention(
  mention: Mention
): mention is TvCaption | TvSuper | TvLogoAppearance {
  return [
    MentionType.tv_caption,
    MentionType.tv_super,
    MentionType.tv_logo_appearance,
  ].includes(mention.type);
}

export function isRadioMention(mention: Mention): mention is RadioClip {
  return mention.type === MentionType.radio_clip;
}

export function isTvOrRadioMention(
  mention: Mention
): mention is RadioClip | TvCaption | TvLogoAppearance | TvSuper {
  return mention.type in BroadcastMentionType;
}

export function isSocialMentionType(
  field: MentionType | SocialMentionType
): field is SocialMentionType {
  return field in SocialMentionType;
}

export function isSocialMention(
  mention: Mention
): mention is Mention & { type: SocialMentionType } {
  return mention.type in SocialMentionType;
}

export function mentionHasUrl(
  mention: Mention
): mention is Extract<Mention, { url: string }> {
  return "url" in mention && typeof mention.url === "string";
}

export function isArticle(
  mention: Mention
): mention is Extract<Article, Article | MagazineArticle | PaperArticle> {
  return [
    MentionType.article,
    MentionType.magazine_article,
    MentionType.paper_article,
  ].includes(mention.type);
}

export function isTranscriptRequestMention(
  mention: Mention
): mention is Extract<
  Mention,
  TvCaption | TvSuper | RadioClip | PodcastEpisode
> {
  return [
    MentionType.tv_caption,
    MentionType.tv_super,
    MentionType.radio_clip,
    MentionType.podcast_episode,
  ].includes(mention.type);
}

export function hasProgramAiring(
  mention: Mention
): mention is Mention & { program_airing: ProgramAiring } {
  return "program_airing" in mention && mention.program_airing !== undefined;
}

export function hasSourceId(
  mention: Mention
): mention is Mention & { source: { id: number } } {
  return "source" in mention && "id" in mention.source;
}
