import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import Organisation from "./Organisation";
import OrganisationSocialIntegration from "./OrganisationSocialIntegration";
import OrganisationTeam from "./OrganisationTeam";
import SocialEngagePostEvent from "./SocialEngagePostEvent";
import SocialEngagePostsIntegration from "./SocialEngagePostsIntegration";
import Tag from "./Tag";
import type { ErrorWithResponse } from "./types";
import User from "./User";

@Model()
class SocialEngagePost extends ApplicationRecord {
  static jsonapiType = "social_engage_posts";

  static notArchived() {
    return this.where({ archivedAt: { eq: "null" } });
  }

  @BelongsTo() organisation: Organisation;

  @BelongsTo() organisationTeam: OrganisationTeam;

  @BelongsTo() user: User;

  @BelongsTo() approver: User;

  @HasMany() socialEngagePostEvents: SocialEngagePostEvent[];

  @HasMany() activeStorageAttachments: ActiveStorageAttachment[];

  @HasMany()
  organisationSocialIntegrations: OrganisationSocialIntegration[];

  @HasMany()
  socialEngagePostsIntegrations: SocialEngagePostsIntegration[];

  @HasMany({ name: "taggable" }) tags: Tag[];

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() approverId: number;

  @Attr() content: string;

  @Attr() scheduledAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() immediate: boolean;

  @Attr({ persist: false }) archivedAt: string;

  @Attr() archived: boolean;

  @Attr() status: string;

  @Attr() facebookText: string;

  @Attr() twitterText: string;

  @Attr() instagramText: string;

  @Attr() linkedinText: string;

  @Attr() mentions: Record<string, any>;

  @Attr() integrationPerformanceTotal: number;

  @Attr() title: string;

  @Attr({ persist: false }) postType: string;

  @Attr() native: boolean;

  @Attr() individualPostTimePerAccount: number;

  @Attr({ persist: false }) createdBySuperUser: boolean;
}

SocialEngagePost.afterFetch = async (
  response,
  json: JSON & { message?: string }
) => {
  if (response.status === 401 && json.message === "sudo access required") {
    const error: ErrorWithResponse = new Error("sudo access required");
    error.response = Object.assign(response, { data: json });

    throw error;
  } else {
    ApplicationRecord.middlewareStack.afterFetch(response, json);
  }
};

export default SocialEngagePost;
