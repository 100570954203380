import endOfDay from "date-fns/endOfDay";
import endOfMonth from "date-fns/endOfMonth";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";

import { getLocaleText } from "shared/boot/i18n";
import {
  dateToTimestamp,
  formatDate,
  formatIntlDate,
  parseDate,
  subtractTime,
} from "shared/helpers/date";

interface DateRangeRangeOption {
  label: string;
  localeKey: string;
  daysBack?: number;
  includeToday?: boolean;
  monthsBack?: number;
  quartersBack?: number;
  fromNow?: boolean;
  localeLabel?: string;
}

interface DateRangeRangeOptions {
  [key: string]: DateRangeRangeOption;
}

interface DateRangeParams {
  before?: number;
  after?: number;
  range?: DateRangeRangeOption | null;
}

interface DateRangeSpanIntervals {
  minutes: number;
  hours: number;
  days: number;
}

const rangeOptions: DateRangeRangeOptions = {
  today: {
    label: "Today",
    localeKey: "date.ranges.today",
    daysBack: 1,
    includeToday: true,
  },
  yesterday: {
    label: "Yesterday",
    localeKey: "date.ranges.yesterday",
    daysBack: 2,
    includeToday: false,
  },
  threeDays: {
    label: "Last 3 Days",
    localeKey: "date.ranges.last_3_days",
    daysBack: 3,
    includeToday: true,
  },
  sevenDays: {
    label: "Last 7 Days",
    localeKey: "date.ranges.last_7_days",
    daysBack: 7,
    includeToday: true,
  },
  fourteenDays: {
    label: "Last 14 Days",
    localeKey: "date.ranges.last_14_days",
    daysBack: 14,
    includeToday: true,
  },
  thirtyDays: {
    label: "Last 30 Days",
    localeKey: "date.ranges.last_30_days",
    daysBack: 30,
    includeToday: true,
  },
  ninetyDays: {
    label: "Last 90 Days",
    localeKey: "date.ranges.last_90_days",
    daysBack: 90,
    includeToday: true,
  },
  oneHundredEightyDays: {
    label: "Last 6 Months",
    localeKey: "date.ranges.last_180_days",
    daysBack: 180,
    includeToday: true,
  },
  currentMonth: {
    label: "This Month",
    localeKey: "date.ranges.this_month",
    monthsBack: 0,
  },
  lastMonth: {
    label: "Last Month",
    localeKey: "date.ranges.last_month",
    monthsBack: 1,
  },
  lastQuarter: {
    label: "Last Quarter",
    localeKey: "date.ranges.last_quarter",
    quartersBack: 1,
    includeToday: true,
  },
  halfYearly: {
    label: "Half Yearly",
    localeKey: "date.ranges.half_yearly",
    quartersBack: 2,
    includeToday: true,
  },
  lastYear: {
    label: "Last 365 Days",
    localeKey: "date.ranges.last_year",
    daysBack: 365,
    includeToday: true,
  },
};

const ONE_HOUR = 3600;
const ONE_DAY = 86400;

function asDateString(timestamp: number, format = "dd MMM yyyy HH:mm"): string {
  return formatDate(parseDate(timestamp), format);
}

function asTimestamp(dateObject: Date): number {
  return dateToTimestamp(dateObject);
}

function getStartOfDay(): Date {
  return startOfDay(new Date());
}

function getEndOfDay(): Date {
  return endOfDay(new Date());
}

/**
 * This class is designed to represent a date range.
 */
export default class DateRange {
  before: number;

  after: number;

  range: DateRangeRangeOption | null;

  constructor({ before, after, range = null }: DateRangeParams = {}) {
    this.before = before!;
    this.after = after!;

    if (range) {
      if (range.localeKey) {
        Object.assign(range, {
          localeLabel: getLocaleText(range.localeKey),
        });
      }

      this.range = range;

      this.calculateRange();
    }
  }

  static today(): DateRange {
    return new DateRange({ range: rangeOptions.today });
  }

  static yesterday(): DateRange {
    return new DateRange({ range: rangeOptions.yesterday });
  }

  static lastThreeDays(): DateRange {
    return new DateRange({ range: rangeOptions.threeDays });
  }

  static lastSevenDays(): DateRange {
    return new DateRange({ range: rangeOptions.sevenDays });
  }

  static lastFourteenDays(): DateRange {
    return new DateRange({ range: rangeOptions.fourteenDays });
  }

  static lastThirtyDays(): DateRange {
    return new DateRange({ range: rangeOptions.thirtyDays });
  }

  static lastNinetyDays(): DateRange {
    return new DateRange({ range: rangeOptions.ninetyDays });
  }

  static lastOneHundredEightyDays(): DateRange {
    return new DateRange({ range: rangeOptions.oneHundredEightyDays });
  }

  static lastYear(): DateRange {
    return new DateRange({ range: rangeOptions.lastYear });
  }

  static currentMonth(): DateRange {
    return new DateRange({ range: rangeOptions.currentMonth });
  }

  static lastMonth(): DateRange {
    return new DateRange({ range: rangeOptions.lastMonth });
  }

  static lastQuarter(): DateRange {
    return new DateRange({ range: rangeOptions.lastQuarter });
  }

  static halfYearly(): DateRange {
    return new DateRange({ range: rangeOptions.halfYearly });
  }

  static rangeForLabel(label: string): DateRange {
    switch (label) {
      case "Today":
        return DateRange.today();
      case "Yesterday":
        return DateRange.yesterday();
      case "Last 3 Days":
        return DateRange.lastThreeDays();
      case "Last 7 Days":
        return DateRange.lastSevenDays();
      case "Last 14 Days":
        return DateRange.lastFourteenDays();
      case "Last 30 Days":
        return DateRange.lastThirtyDays();
      case "Last 90 Days":
        return DateRange.lastNinetyDays();
      case "Last 180 Days":
        return DateRange.lastOneHundredEightyDays();
      case "This Month":
        return DateRange.currentMonth();
      case "Last Month":
        return DateRange.lastMonth();
      case "Last Quarter":
        return DateRange.lastQuarter();
      case "Half Yearly":
        return DateRange.halfYearly();
      case "Last 365 Days":
        return DateRange.lastYear();
      default:
        return DateRange.today();
    }
  }

  static fromDates(after: Date, before: Date): DateRange {
    return new DateRange({
      after: asTimestamp(after),
      before: asTimestamp(before),
    });
  }

  static clone(dateRange: DateRange): DateRange {
    return new DateRange(dateRange.attributes());
  }

  get span(): number {
    return this.before - this.after;
  }

  get interval(): string {
    return this.span <= 24 * ONE_HOUR ? "1h" : "1d";
  }

  get rangeText(): string {
    const { span } = this;

    if (span < ONE_HOUR)
      return getLocaleText("date.pretty_ranges.last_minutes", {
        span: Math.round(span / 60),
      });
    if (span < ONE_DAY)
      return getLocaleText("date.pretty_ranges.last_hours", {
        span: Math.round(span / ONE_HOUR),
      });
    if (span === ONE_DAY) return getLocaleText("date.pretty_ranges.last_day");

    return getLocaleText("date.pretty_ranges.last_days", {
      span: Math.round(span / ONE_DAY),
    });
  }

  get prettyRangeText(): string {
    const label = this.range?.label;
    const localeLabel = this.range?.localeLabel;

    switch (label) {
      case "Today":
      case "Yesterday":
        return getLocaleText("date.pretty_ranges.today_yesterday");
      case "Last 3 Days":
      case "Last 7 Days":
      case "Last 14 Days":
      case "Last 30 Days":
      case "Last 90 Days":
      case "Last 180 Days":
        return localeLabel!.toLowerCase();
      case "This Month": {
        const days = Math.round(this.span / ONE_DAY);

        return getLocaleText("date.pretty_ranges.this_month", { days });
      }
      case "Last Month":
        return getLocaleText("date.pretty_ranges.last_month");
      case "Last 365 Days":
        return getLocaleText("date.pretty_ranges.last_year");
      case "Last Quarter":
        return getLocaleText("date.pretty_ranges.last_quarter");
      default:
        return getLocaleText("date.pretty_ranges.default", {
          rangeText: this.rangeText,
        });
    }
  }

  get spanIntervals(): DateRangeSpanIntervals {
    return {
      minutes: Math.ceil(this.span / 60),
      hours: Math.ceil(this.span / ONE_HOUR),
      days: Math.ceil(this.span / (24 * ONE_HOUR)),
    };
  }

  get localRangeLabel(): string {
    return this.range?.localeLabel || "";
  }

  get rangeLabel(): string {
    return this.range?.label || "";
  }

  attributes(): DateRangeParams {
    return {
      before: this.before,
      after: this.after,
      range: this.range,
    };
  }

  equals(dateRange: DateRange | unknown): boolean {
    if (!(dateRange instanceof DateRange)) return false;

    return (
      JSON.stringify(this.attributes()) ===
      JSON.stringify(dateRange.attributes())
    );
  }

  toString(format = "d MMM", options = { forceRange: false }): string {
    return this.range && !options.forceRange
      ? this.range.localeLabel!
      : getLocaleText("date.pretty_ranges.range", {
          after: this.afterAsString(format),
          before: this.beforeAsString(format),
        });
  }

  toIntlString(options: Intl.DateTimeFormatOptions = {}): string {
    return this.range
      ? this.range.localeLabel!
      : getLocaleText("date.pretty_ranges.range", {
          after: formatIntlDate(this.after, options),
          before: formatIntlDate(this.before, options),
        });
  }

  beforeAsString(format = "dd MMM yyyy HH:mm"): string {
    return asDateString(this.before, format);
  }

  afterAsString(format = "dd MMM yyyy HH:mm"): string {
    return asDateString(this.after, format);
  }

  calculateQuarters(quartersBack: number): DateRangeParams {
    const afterTimestamp = new Date();
    // Go back in time based on quarters back
    afterTimestamp.setMonth(afterTimestamp.getMonth() - 3 * quartersBack);

    // round to nearest quarter
    const quarterStartMonth = Math.floor(afterTimestamp.getMonth() / 3) * 3;
    afterTimestamp.setMonth(quarterStartMonth);
    afterTimestamp.setDate(1);
    afterTimestamp.setHours(0, 0, 0, 0);

    const beforeTimestamp = new Date();

    let quarterEndMonth;

    if (beforeTimestamp.getMonth() <= 2) {
      quarterEndMonth = 12;
    } else if (beforeTimestamp.getMonth() <= 5) {
      quarterEndMonth = 3;
    } else if (beforeTimestamp.getMonth() <= 8) {
      quarterEndMonth = 6;
    } else {
      quarterEndMonth = 9;
    }

    if (quartersBack === 1) {
      beforeTimestamp.setFullYear(afterTimestamp.getFullYear());
    }

    beforeTimestamp.setMonth(quarterEndMonth);
    beforeTimestamp.setDate(0);
    beforeTimestamp.setHours(23, 59, 59, 999);

    return {
      before: asTimestamp(beforeTimestamp),
      after: asTimestamp(afterTimestamp),
    };
  }

  calculateRange(): void {
    if (this.range!.monthsBack !== undefined) {
      const endOfCurrentDay = endOfDay(new Date());

      const after = subMonths(
        startOfMonth(getStartOfDay()),
        this.range!.monthsBack
      );

      let before = endOfDay(endOfMonth(after));

      if (before > endOfCurrentDay) before = endOfCurrentDay;

      this.after = asTimestamp(after);
      this.before = asTimestamp(before);
    } else if (this.range!.quartersBack !== undefined) {
      const { before, after } = this.calculateQuarters(
        this.range!.quartersBack
      );

      this.before = before!;
      this.after = after!;
    } else if (this.range!.fromNow) {
      const now = new Date();
      this.before = asTimestamp(now);
      this.after = asTimestamp(subtractTime(now, this.range!.daysBack!, "day"));
    } else {
      this.after = asTimestamp(
        subtractTime(getStartOfDay(), this.range!.daysBack! - 1, "day")
      );

      this.before = asTimestamp(
        this.range!.includeToday ? getEndOfDay() : getStartOfDay()
      );
    }
  }

  justifyRange(interval: string): void {
    const { span } = this;
    const intervalType = interval[interval.length - 1];
    const intervalSize = parseInt(interval.slice(0, interval.length - 1), 10);

    const intervalSeconds: { [key: string]: number } = {
      s: 1,
      m: 60,
      h: 3600,
      d: 86400,
    };

    const secondsPerInterval = intervalSize * intervalSeconds[intervalType];
    const intervalsOdd = Number(span / secondsPerInterval) % 2 !== 0;

    const leftOverFromRange = span % secondsPerInterval;
    const secondsToAdd = secondsPerInterval - leftOverFromRange;

    if (intervalsOdd) {
      this.before += secondsToAdd;
    }
  }
}
