import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContactList from "./ContactList";
import ContactListsContact from "./ContactListsContact";
import Organisation from "./Organisation";
import type { Email, Homepage, Phone, Social } from "./types";

@Model()
class PrivateOutlet extends ApplicationRecord {
  static jsonapiType = "private_outlets";

  @BelongsTo() organisation: Organisation;

  @HasMany() contactListsContacts: ContactListsContact[];

  @HasMany() contactLists: ContactList[];

  @Attr() name: string;

  @Attr() email: string;

  @Attr({ persist: false }) emails: Email[];

  @Attr() phone: string;

  @Attr({ persist: false }) phones: Phone[];

  @Attr({ persist: false }) homepages: Homepage[];

  @Attr({ persist: false }) faxes: Phone[];

  @Attr({ persist: false }) socials: Social[];

  @Attr() note: string;

  @Attr() organisationId: number;

  @Attr({ persist: false }) documentId: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() invalidEmail: boolean;
}

export default PrivateOutlet;
