import { Attr, HasMany, Model } from "spraypaint";

import ContentVersion from "shared/resources/ContentVersion";
import { ApplicationRecord } from "shared/services/spraypaint";
import type { Nullable } from "shared/types/utility";

@Model()
class AIFeatureConfiguration extends ApplicationRecord {
  static jsonapiType = "ai/feature_configurations";

  @HasMany() versions: ContentVersion[];

  @Attr() aiProvider: number;

  @Attr() aiProviderModel: number;

  @Attr() feature: number;

  @Attr() internalVersion: string;

  @Attr() outputFormat: string;

  @Attr() outputTokenLimit: number;

  @Attr() safetySettings: Nullable<Record<string, unknown>>;

  @Attr() stopSequences: Nullable<Record<string, unknown>>;

  @Attr() temperature: string;

  @Attr() topK: number;

  @Attr() topP: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default AIFeatureConfiguration;
