import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContactList from "./ContactList";
import ContactListsContact from "./ContactListsContact";
import Outlet from "./Outlet";
import type { Email, Phone } from "./types";

@Model()
class OutletDesk extends ApplicationRecord {
  static jsonapiType = "outlet_desks";

  @BelongsTo() outlet: Outlet;

  @HasMany() contactListsContacts: ContactListsContact[];

  @HasMany() contactLists: ContactList[];

  @Attr() name: string;

  @Attr() email: string;

  @Attr() phone: string;

  @Attr() outletId: number;

  @Attr({ persist: false }) documentId: string;

  @Attr({ persist: false }) emails: Email[];

  @Attr({ persist: false }) phones: Phone[];

  @Attr({ persist: false }) invalidEmail: boolean;
}

export default OutletDesk;
