export default [
  {
    name: "organisation-insights",
    path: "/insights/:organisationId/:reportType?",
    props: (route) => ({
      view: route.query.view,
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/insights/OrganisationInsights.vue"),
    meta: {
      title: "Organisation Insights",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-query-add",
    path: "/insights/:organisationId/:reportType/insights-query/new",
    props: true,
    component: () => import("pages/insights/EditInsightsQuery.vue"),
    meta: {
      title: "Create Query",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-query-edit",
    path: "/insights/:organisationId/:reportType/insights-query/:queryId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsQuery.vue"),
    meta: {
      title: "Edit Query",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-topic-new",
    path: "/insights/:organisationId/:reportType/insights-topic/new",
    props: true,
    component: () => import("pages/insights/EditInsightsQuery.vue"),
    meta: {
      title: "Create Topic",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-topic-edit",
    path: "/insights/:organisationId/:reportType/insights-topic/:queryId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsQuery.vue"),
    meta: {
      title: "Edit Topic",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-report-add",
    path: "/insights/:organisationId/:reportType/insights-report/new",
    props: (route) => ({
      baseReportId: route.query.baseReportId,
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/insights/EditInsightsReport.vue"),
    meta: {
      title: "Create Insights Report",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-report-edit",
    path: "/insights/:organisationId/:reportType/insights-report/:reportId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsReport.vue"),
    meta: {
      title: "Edit Insights Report",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-entity-add",
    path: "/insights/:organisationId/insights-entity/new",
    props: true,
    component: () => import("pages/insights/EditInsightsEntity.vue"),
    meta: {
      title: "Create Insights Entity",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-entity-edit",
    path: "/insights/:organisationId/insights-entity/:entityId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsEntity.vue"),
    meta: {
      title: "Edit Insights Entity",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-report-qualitative",
    path: "/insights/:organisationId/insights-report/:reportId/queries",
    props: true,
    component: () => import("pages/insights/InsightsReportPage.vue"),
    meta: {
      title: "View Insights Qualitative Report",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-sentiment-analysis-edit",
    path: "/insights/:organisationId/insights-report/:reportId/sentiment-rating/:queryId",
    props: (route) => ({
      view: route.query.view,
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/insights/EditInsightsSentimentAnalysis.vue"),
    meta: {
      title: "Insights Qualitative analysis",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-qualitative-topic-new",
    path: "/insights/:organisationId/insights-qualitative-topic/new",
    props: true,
    component: () => import("pages/insights/EditInsightsQualitativeTopic.vue"),
    meta: {
      title: "Create Qualitative Topic",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-qualitative-topic-edit",
    path: "/insights/:organisationId/insights-qualitative-topic/:topicId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsQualitativeTopic.vue"),
    meta: {
      title: "Edit Qualitative Topic",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-key-message-new",
    path: "/insights/:organisationId/insights-key-message/new",
    props: true,
    component: () => import("pages/insights/EditInsightsQualitativeTopic.vue"),
    meta: {
      title: "Create Key Message",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-key-message-edit",
    path: "/insights/:organisationId/insights-key-message/:topicId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsQualitativeTopic.vue"),
    meta: {
      title: "Edit Key Message",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-report-group-add",
    path: "/insights/:organisationId/:reportType/insights-report-group/new",
    props: true,
    component: () => import("pages/insights/EditInsightsReportGroup.vue"),
    meta: {
      title: "Create Insights Report Group",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-report-group-edit",
    path: "/insights/:organisationId/:reportType/insights-report-group/:groupId/edit",
    props: true,
    component: () => import("pages/insights/EditInsightsReportGroup.vue"),
    meta: {
      title: "Edit Insights Report Group",
      accessLevel: "media_analyst",
    },
  },
];
