import { Attr, BelongsTo, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Contact from "./Contact";
import CoreVersion from "./CoreVersion";
import Organisation from "./Organisation";

@Model()
class ContactDetail extends ApplicationRecord {
  static jsonapiType = "contact_details";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() contact: Contact;

  @HasOne() latestVersion: CoreVersion;

  @Attr() address: Record<string, string>;

  @Attr() contactId: number;

  @Attr() contactType: string;

  @Attr() defaultEmail: boolean;

  @Attr() email: string;

  @Attr() note: string;

  @Attr() organisationId: number;

  @Attr() phone: string;

  @Attr() invalidEmail: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default ContactDetail;
